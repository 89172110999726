.pauseIcon {
  color: #edeff5;
}
.pauseIcon:hover {
  color: #f9dada;
}
.utter-notification {
  color: #ff4141;
  border: 1px solid #ff4141;
  padding: 5px 15px;
  border-radius: 10px;
  margin-left: -100px;
  font-weight: 600;
  font-size: 12px;
}

.helphead {
  margin-top: 40px;
}

.helpmsg {
  margin-top: 20px;
  margin-bottom: 40px;
  font-size: 18px;
  line-height: 24px;
}

.helpmsg p {
  margin-bottom: 10px;
}

/* Add this to your CSS file or a style block in your component */
@keyframes rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.rotate {
  animation: rotate 1s linear infinite;
}